import React, { Component } from 'react';
import {Outlet, Link} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavLink from 'react-bootstrap/NavLink';
import logo from './images/logo.png';
import {NavDropdown} from "react-bootstrap";


export default class Layout extends Component {
    constructor() {
      super()
      this.state = {
        balance: 0,
        me: {},
      }

      fetch("/api/getMyBalance.php")
        .then(response => {
          if (response.status === 401) window.location.href = '/#/login'
          if (response.ok) return response.json()
          throw response
        })
        .then(data => {
            document.getElementById("NavbarBalance").textContent = data.toFixed(2) + "€"
            this.setState({balance: data.toFixed(2)})
        })
        .catch(error => console.log('error', error))

      fetch("/api/getMyUser.php")
        .then(response => {
          if (response.status === 401) window.location.href = '/#/login'
          if (response.ok) return response.json()
          throw response
        })
        .then(data => this.setState({ me: data }))
        .catch(error => console.log('error', error))
    }

    render() {
        var isAdmin = (this.state.me.role === "admin")
      return (
        <>
            <Navbar collapseOnSelect expand="lg" bg="primary" variant="primary">
                <div className="container-fluid">
                    <Navbar.Brand className="order-1">
                        <a className="navbar-brand" href="/">
                            <img src={logo} alt="Logo" width="30" height="30" className="d-inline-block align-text-top me-3" />
                            BEV
                        </a>
                    </Navbar.Brand>
                    <Navbar.Toggle className="order-3 order-lg-2" aria-controls="navbarScroll" data-bs-target="#navbarScroll" />
                    <Navbar.Collapse className="order-4 order-lg-3" id="navbarScroll">
                        <Nav>
                            <NavLink eventKey="1" as={Link} to="/productlist">Produktliste</NavLink>
                            <NavLink eventKey="2" as={Link} to="/transactions">Meine Transaktionen</NavLink>
                            {isAdmin && <NavDropdown title="Admin">
                                <NavDropdown.Item eventKey="3" as={Link} to="/admin">Admin-Produkte</NavDropdown.Item>
                                <NavDropdown.Item eventKey="4" as={Link} to="/admin/admin-users">Admin-User</NavDropdown.Item>
                                <NavDropdown.Item eventKey="5" as={Link} to="/admin/admin-finance">Admin-Finanzen</NavDropdown.Item>
                            </NavDropdown>}

                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Brand className="order-2 order-lg-4">
                        <span id="NavbarBalance"></span>
                    </Navbar.Brand>
                </div>
            </Navbar>

            <Outlet />
        </>
      );
    }
  }

