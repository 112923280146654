import React, { Component, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import imageCompression from 'browser-image-compression';

export default class AdminAddDrink extends Component {
    constructor() {
        super()
        this.state = {
            name: "",
            image: "",
            price: 0.00,
            stock: 0,
            category: null,
            categories: []
        };

        fetch("/api/getCategoryList.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                if (data.message) {
                    this.setState({categories: []})
                }
                else {
                    this.setState({
                        categories: data
                    });
                }
            })
            .catch(error => console.log('error', error))
    }

    handleProductNameChange = (e) => {
        const updatedName = e.target.value;
        this.setState(prevState => ({
            name: updatedName
        }));
    }

    handleProductPriceChange = (e) => {
        const updatedPrice = e.target.value;
        this.setState(prevState => ({
            price: updatedPrice
        }));
    }

    handleProductStockChange = (e) => {
        const updatedStock = e.target.value;
        this.setState(prevState => ({
            stock: updatedStock
        }));
    }

    handleProductCategoryChange = (e) => {
        const updatedCategory = e.target.value;
        this.setState(prevState => ({
            category: updatedCategory
        }));
    }

    handleSafeBtnPress(state) {
        if (state.name === "") return;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("name", state.name);
        urlencoded.append("stock", state.stock);
        urlencoded.append("price", state.price);
        urlencoded.append("disabled", "0");
        urlencoded.append("image", state.image);
        urlencoded.append("category_id", state.category);

        fetch("/api/createDrink.php", {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        })
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login';
                if (response.ok) {
                    console.log("Änderung erfolgreich gespeichert");
                    window.location.href = '/#/admin'
                } else {
                    throw response;
                }
            })
            .catch(error => console.log('error', error));
    }

    handleImgUpload = async (e) => {
        const updatedImg = e.target.files[0];

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 500,
            useWebWorker: true,
        };

        try {
            const compressedFile = await imageCompression(updatedImg, options);
            this.toBase64(compressedFile)
                .then(base64 => {
                    this.setState(prevState => ({
                        image: base64
                    }));
                })
                .catch(err => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    render() {
        let state = this.state
        const categorielist = this.state.categories.map((categorie) => {
            return (
                <option value={categorie.key}>{categorie.value}</option>
            );
        });

        return (
            <div className="mt-4 d-flex justify-content-center align-content-center flex-column flex-wrap">
                <Link className="noUnderline btn btn-secondary text-white fit-content" to={'/admin'}>Zurück</Link>
                <div className="mb-3 w-100">
                    <div className="mb-3 text-center">
                        <img className="img-fluid imgHeigth mb-3" alt="Produktbild" src={state.image}/>
                        <div className="input-group mb-3">
                            <input type="file" className="form-control" onChange={this.handleImgUpload} accept="image/*"/>
                            <label className="input-group-text" htmlFor="inputGroupFile02">Upload</label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            className="form-control"
                            value={state.name}
                            onChange={this.handleProductNameChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Preis:</label>
                        <input
                            className="form-control"
                            value={state.price}
                            onChange={this.handleProductPriceChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Anzahl:</label>
                        <input
                            className="form-control"
                            value={state.stock}
                            onChange={this.handleProductStockChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Kategorie:</label>
                        <select className="form-select" value={state.category} aria-label="Default select example"
                                onChange={this.handleProductCategoryChange}>
                            <option value={null}>Keine</option>
                            {categorielist}
                        </select>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="btn btn-success" onClick={() => {this.handleSafeBtnPress(state)}}>
                        Speichern
                    </div>
                </div>
            </div>
        );
    }
}