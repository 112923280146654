import React, { Component, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import imageCompression from 'browser-image-compression';

export default class AdminEditDrink extends Component {
    constructor() {
        super()
        this.state = {
            product: null,
            productid: window.location.href.split('/')[6],
            categories:[]
        };

        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

        let urlencoded = new URLSearchParams()
        urlencoded.append("Drink_ID", this.state.productid)

        fetch("/api/getSingleDrink.php", {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        })
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {

                data.disabled = data.disabled === "1";

                this.setState({
                    product: data
                })
            })
            .catch(error => console.log('error', error))

        fetch("/api/getCategoryList.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                if (data.message) {
                    this.setState({categories: []})
                }
                else {
                    this.setState({
                        categories: data
                    });
                }
            })
            .catch(error => console.log('error', error))
    }

    handleProductNameChange = (e, productid) => {
        const updatedName = e.target.value;
        this.setState(prevState => ({
            product: {
                ...prevState.product,
                name: updatedName
            }
        }));
    }

    handleProductPriceChange = (e) => {
        const updatedPrice = e.target.value;
        this.setState(prevState => ({
            product: {
                ...prevState.product,
                price: updatedPrice
            }
        }));
    }

    handleProductStockChange = (e) => {
        const updatedStock = e.target.value;
        this.setState(prevState => ({
            product: {
                ...prevState.product,
                stock: updatedStock
            }
        }));
    }

    handleProductCategoryChange = (e) => {
        const updatedCategory = e.target.value;
        this.setState(prevState => ({
            product: {
                ...prevState.product,
                category_id: updatedCategory
            }
        }));
    }

    handleDisableDrink = (productId) => {
        this.setState(prevState => {
            const isCurrentlyDisabled = prevState.product.disabled
            const newStatus = !isCurrentlyDisabled;  // Umschalten des Zustands

            return {
                product: {
                    ...prevState.product,
                    disabled: newStatus
                }
            };
        });
    }

    handleSafeBtnPress(product) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("drink_id", product.key);
        urlencoded.append("name", product.name);
        urlencoded.append("stock", product.stock);
        urlencoded.append("price", product.price);
        urlencoded.append("disabled", product.disabled);
        urlencoded.append("image", product.image);
        urlencoded.append("category_id", product.category_id);

        fetch("/api/updateDrink.php", {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        })
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login';
                if (response.ok) {
                    console.log("Änderung erfolgreich gespeichert");
                    window.location.href = '/#/admin'
                } else {
                    throw response;
                }
            })
            .catch(error => console.log('error', error));
    }

    handleImgUpload = async (e) => {
        const updatedImg = e.target.files[0];

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 500,
            useWebWorker: true,
        };

        try {
            const compressedFile = await imageCompression(updatedImg, options);
            this.toBase64(compressedFile)
                .then(base64 => {
                    this.setState(prevState => ({
                        product: {
                            ...prevState.product,
                            image: base64
                        }
                    }));
                })
                .catch(err => console.log(err));
        } catch (error) {
            console.log(error);
        }
    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    render() {
        let {product} = this.state
        if ( product == null) {
            return;
        }
        const categorielist = this.state.categories.map((categorie) => {
            return (
                <option value={categorie.key}>{categorie.value}</option>
            );
        });
        return (
            <div className="mt-4 d-flex justify-content-center align-content-center flex-column flex-wrap">
                <Link className="noUnderline btn btn-secondary text-white fit-content" to={'/admin'}>Zurück</Link>
                <div className="mb-3 w-100">
                    <div className="mb-3 text-center">
                        <img className="img-fluid imgHeigth mb-3" alt="Produktbild" src={product.image}/>
                        <div className="input-group mb-3">
                            <input type="file" className="form-control" onChange={this.handleImgUpload} accept="image/*"/>
                            <label className="input-group-text" htmlFor="inputGroupFile02">Upload</label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            className="form-control"
                            value={product.name}
                            onChange={this.handleProductNameChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Preis:</label>
                        <input
                            className="form-control"
                            value={product.price}
                            onChange={this.handleProductPriceChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Anzahl:</label>
                        <input
                            className="form-control"
                            value={product.stock}
                            onChange={this.handleProductStockChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Kategorie:</label>
                        <select className="form-select" value={product.category} aria-label="Default select example"
                                onChange={this.handleProductCategoryChange}>
                            <option value={null}>Keine</option>
                            {categorielist}
                        </select>
                    </div>
                    <div className="mb-3 d-flex flex-row">
                        <div className="d-flex align-content-center flex-wrap fw-bolder">
                            Verfügbar:
                        </div>
                        <div
                            className={`btn btn-${product.disabled ? "primary" : "secondary"} ms-2`}
                            onClick={() => this.handleDisableDrink(product.key)}
                        >
                            {product.disabled ? "Aktivieren" : "Deaktivieren"}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="btn btn-success" onClick={() => {this.handleSafeBtnPress(product)}}>
                        Speichern
                    </div>
                </div>
            </div>
        );
    }
}