import React, { useState, useEffect } from 'react';
import logo from './images/logo.png';


export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {error: false};

    this.login = this.login.bind(this);

    document.addEventListener('keyup', (e) => {
      if (e.key === "Enter") {
        this.login()
      }
    })

  }

  login() {
    let myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    let urlencoded = new URLSearchParams()
    let email = document.getElementById('inputEmail').value
    let password = document.getElementById('inputPassword').value
    urlencoded.append("email", email)
    urlencoded.append("password", password)

    if(email && password) {
      fetch("/api/login.php", {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      })
        .then(response => {
          console.log(response)
          if (response.status === 401) this.setState({error: true})
          if (response.ok) window.location.href = '/'
          throw response
        })
        .catch(error => console.log('error', error))
    } else this.setState({error: true})

  }

  render() {
    let errorHTML = ''
    let {error} = this.state
    if(error)
      errorHTML = <label>E-Mail oder Passwort sind falsch</label>

    return (
      <div className="container">
      <div className="text-center mt-5">
        <img src={logo} alt="Logo" className="logo mb-4" />
      </div>
      <div className="login-container">
        <div className="form-group">
          {errorHTML}
        </div>
        <div className="form-group mt-3">
          <label htmlFor="inputEmail">Email-Adresse</label>
          <input type="email" className="form-control" id="inputEmail" name="email" required />
        </div>
        <div className="form-group mt-3">
          <label htmlFor="inputPassword">Passwort</label>
          <input type="password" className="form-control" id="inputPassword" name="password" required />
        </div>
        <button onClick={this.login} className="btn btn-success mt-4">Login</button>
      </div>
    </div>
    );
  }
}
