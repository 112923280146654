import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
        products: [],
        categories: []
    };

    fetch("/api/getDrinkList.php")
      .then(response => {
        if (response.status === 401) window.location.href = '/#/login'
        if (response.ok) return response.json()
        throw response
      })
      .then(data => {
          if (data.message) {
              this.setState({products: []})
          }
          else {
              const filteredProducts = data.filter(product => product.disabled === "0");

              this.setState({products: filteredProducts})
          }

      })
      .catch(error => console.log('error', error))

      fetch("/api/getCategoryList.php")
          .then(response => {
              if (response.status === 401) window.location.href = '/#/login'
              if (response.ok) return response.json()
              throw response
          })
          .then(data => {
              if (data.message) {
                  this.setState({categories: []})
              }
              else {
                  this.setState({
                      categories: data
                  });
              }
          })
          .catch(error => console.log('error', error))
  }

    render() {
        const { products, categories } = this.state;

        // Produkte, die keiner Kategorie zugewiesen sind, finden
        const noCategoryProducts = products.filter(product => !product.category_id || !categories.some(cat => cat.key === product.category_id));

        return (
            <div className="container mt-4 mb-4">
                <h1>Produkte</h1>

                {categories.map(category => {
                    const productsInCategory = products.filter(product => product.category_id === category.key);
                    if (productsInCategory.length === 0) return null;

                    return (
                        <div key={category.key}>
                            <h2 className="header-product-list">{category.value}</h2>
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 productlist">
                                {productsInCategory.map(product => (
                                    <div className="col" key={product.key}>
                                        <Card className={`product ${product.stock === "0" ? "card-with-stamp" : ""}`}>
                                            {product.stock === "0" && <div className="sold-out-stamp">Ausverkauft</div>}
                                            <div className="img d-flex">
                                                <Card.Img className="img-fluid mx-auto" variant="top" src={product.image} />
                                            </div>
                                            <Card.Body className="text-center">
                                                <Card.Title>{product.name}</Card.Title>
                                                <Card.Text className={product.stock === "0" ? "card-with-stamp-text" : ""}>
                                                    Preis: {product.price}€<br />
                                                    Verfügbare Anzahl: {product.stock}
                                                </Card.Text>
                                                {product.stock !== "0" &&
                                                    <Link className="stretched-link" to={'/buyproduct/' + product.key} />
                                                }
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                })}

                {noCategoryProducts.length > 0 &&
                    <div>
                        <h2 className="header-product-list">Sonstiges</h2>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 g-4 productlist">
                            {noCategoryProducts.map(product => (
                                <div className="col" key={product.key}>
                                    <Card className={`product ${product.stock === "0" ? "card-with-stamp" : ""}`}>
                                        {product.stock === "0" && <div className="sold-out-stamp">Ausverkauft</div>}
                                        <div className="img d-flex">
                                            <Card.Img className="img-fluid mx-auto" variant="top" src={product.image} />
                                        </div>
                                        <Card.Body className="text-center">
                                            <Card.Title>{product.name}</Card.Title>
                                            <Card.Text className={product.stock === "0" ? "card-with-stamp-text" : ""}>
                                                Preis: {product.price}€<br />
                                                Verfügbare Anzahl: {product.stock}
                                            </Card.Text>
                                            {product.stock !== "0" &&
                                                <Link className="stretched-link" to={'/buyproduct/' + product.key} />
                                            }
                                        </Card.Body>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
        );
    }
}
