import React, { Component, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";

export default class AdminDashboardProducts extends Component {
    constructor() {
        super()
        this.state = {
            products: [],
            disabledDrinks: {},
            categories: []
        };

        fetch("/api/getDrinkList.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                if (data.message) {
                    this.setState({products: []})
                }
                else {
                    const disabledStatus = {};
                    data.forEach(product => {
                        disabledStatus[product.key] = product.disabled === "1";
                    });

                    this.setState({
                        products: data,
                        disabledDrinks: disabledStatus,
                    });
                }
            })
            .catch(error => console.log('error', error))

        fetch("/api/getCategoryList.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                if (data.message) {
                    this.setState({categories: []})
                }
                else {
                    this.setState({
                        categories: data
                    });
                }
            })
            .catch(error => console.log('error', error))

    }

    render() {
        const productlist = this.state.products.map((product) => {
            // Überprüfen, ob das Produkt deaktiviert ist
            const isDisabled = this.state.disabledDrinks[product.key];

            return (
                <tr key={product.key} className={isDisabled ? "table-secondary" : ""}>
                    <td className="text-center">{product.name}</td>
                    <td className="text-center">
                        <div>{product.price}</div>
                    </td>
                    <td className={product.stock <= 10 ? 'text-danger fw-bold text-center' : 'text-center'}>{product.stock}</td>
                    <td className="text-center">
                        <div className="">
                            {isDisabled.toString()}
                        </div>
                    </td>
                    <td className="text-center">
                        <div className="btn btn-success">
                            <Link className="text-white noUnderline" to={'/admin/editdrink/' + product.key} >Bearbeiten</Link>
                        </div>
                    </td>
                </tr>
            );
        });

        const categorielist = this.state.categories.map((category) => {
            // Überprüfen, ob das Produkt deaktiviert ist

            return (
                <tr key={category.key} >
                    <td className="text-center">{category.value}</td>
                    <td className="text-center">
                        <div>{category.drink_count}</div>
                    </td>
                    <td className="text-center">
                        <div className="btn btn-success">
                            <Link className="text-white noUnderline" to={'/admin/editcategory/' + category.key} >Bearbeiten</Link>
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <div className="container mt-4">
                <div className="fw-bold mb-2">
                    Produkte:
                </div>
                <Table bordered>
                    <thead>
                    <tr>
                        <th>Produkt</th>
                        <th>Preis</th>
                        <th>Anzahl</th>
                        <th>Deaktiviert</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {productlist}
                    </tbody>
                </Table>
                <div className="d-flex justify-content-center mb-4">
                    <button className="btn btn-success" onClick={() => {window.location.href = '/#/admin/adddrink'}}>Produkt hinzufügen</button>
                </div>
                <div className="fw-bold mb-2">
                    Produkt-Kategorien:
                </div>
                <Table bordered>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Anzahl Produkte</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {categorielist}
                    </tbody>
                </Table>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-success" onClick={() => {window.location.href = '/#/admin/addcategory'}}>Produkt-Kategorie hinzufügen</button>
                </div>
            </div>
        );
    }
}