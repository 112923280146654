import React, { Component } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'


export default class BuyProductChooseUser extends Component {
  constructor() {
    super()
    this.state = {
      allUsers: [],
      users: [],
      me: {},
      product: {},
      productid: window.location.href.split('/')[5],
    }

    this.handleSearch = this.handleSearch.bind(this);

    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    var urlencoded = new URLSearchParams()
    urlencoded.append("Drink_ID", this.state.productid)

    fetch("/api/getSingleDrink.php", {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    })
      .then(response => {
        if (response.status === 401) window.location.href = '/#/login'
        if (response.ok) return response.json()
        throw response
      })
      .then(data => this.setState({product: data}))
      .catch(error => console.log('error', error))

    fetch("/api/getMyUser.php")
      .then(response => {
        if (response.status === 401) window.location.href = '/#/login'
        if (response.ok) return response.json()
        throw response
      })
      .then(data_me => {
        this.setState({me: data_me})
        fetch("/api/getUserList.php")
          .then(response => {
            if (response.status === 401) window.location.href = '/#/login'
            if (response.ok) return response.json()
            throw response
          })
          .then(data => {
            let arr = []
            for (const element of data) {
              if (parseInt(element.key) !== data_me.user_id && element.role !== "kiosk") arr.push(element)
            }
            this.setState({allUsers: arr})
            this.setState({users: arr})
          })
          .catch(error => console.log('error', error))
      })
      .catch(error => console.log('error', error))
  }

  handleSearch() {
    let value = document.getElementById("searchinput").value
    let {allUsers} = this.state
    if (value) {
      this.setState({users: allUsers.filter(function (el) {
        return el.name.toLowerCase().includes(value.toLowerCase())
      })})
    } else this.setState({users: allUsers})
  }

  render() {
    let {product, me} = this.state
    const userlist = this.state.users.map((user) => (
      <Button className="mt-2 w-100 btn btn-success" href={'/#/buyproduct/' + product.key + '/' + user.key}>{user.name}</Button>
    ))
  
    return (
      <div className="container mt-4">
      <Button href='/#/productlist' className="mb-3" variant="outline-danger">Abbrechen</Button>
        <div class="row justify-content-around mt-3">
          <div class="col-12 col-md-6 col-lg-4">
            <p className="h3 text-center">1x {product.name} kaufen für {product.price}€</p>
            <div className="d-flex">
              <img className="img-fluid mx-auto w-25" alt="Produktbild" src={product.image} />
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4 align-self-center">
            {me.role !== 'kiosk' ? <Button className="mb-2 w-100 btn btn-success" href={'/#/buyproduct/' + product.key + '/' + me.user_id}>Für mich kaufen</Button> : ''}
            <hr />
            <InputGroup className="mt-2">
              <Form.Control
                id="searchinput"
                placeholder="Suche"
                aria-label="Suche"
                onChange={this.handleSearch}
                aria-describedby="searchbutton"
                className="border-secondary"
              />
              <Button onClick={this.handleSearch} className="btn btn-success" id="searchbutton">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            </InputGroup>
            {userlist}
          </div>
        </div>
      </div>
    );
  }
}

