import React, { Component, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";

export default class AdminDashboardUsers extends Component {
    constructor() {
        super()
        this.state = {
            users: []
        };

        fetch("/api/getAdminUserList.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                if (data.message) {
                    this.setState({users: []})
                }
                else {
                    this.setState({
                        users: data
                    });
                }
            })
            .catch(error => console.log('error', error))

    }

    render() {
        const userlist = this.state.users.map((user) => {
            // Überprüfen, ob das Produkt deaktiviert ist
            console.log(user)

            return (
                <tr key={user.key}>
                    <td className="text-center">{user.name}</td>
                    <td className="text-center">
                        <div>{user.role}</div>
                    </td>
                    <td className="text-center">
                        <div>{user.balance}</div>
                    </td>
                    <td className="text-center">
                        <div className="btn btn-success">
                            <Link className="text-white noUnderline" to={'/admin/edituser/' + user.key} >Bearbeiten</Link>
                        </div>
                    </td>
                </tr>
            );
        });

        return (
            <div className="container mt-4">
                <div className="fw-bold mb-2">
                    Produkte:
                </div>
                <Table bordered>
                    <thead>
                    <tr>
                        <th>Produkt</th>
                        <th>Rolle</th>
                        <th>Guthaben</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {userlist}
                    </tbody>
                </Table>
            </div>
        );
    }
}