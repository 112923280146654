import React, { Component, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";
import imageCompression from 'browser-image-compression';

export default class AdminAddCategory extends Component {
    constructor() {
        super()
        this.state = {
            value: ""
        };
    }

    handleCategoryValueChange = (e) => {
        const updatedName = e.target.value;
        this.setState(prevState => ({
            value: updatedName
        }));
    }

    handleSafeBtnPress(state) {
        if (state.name === "") return;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("value", state.value);

        fetch("/api/createCategory.php", {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        })
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login';
                if (response.ok) {
                    console.log("Änderung erfolgreich gespeichert");
                    window.location.href = '/#/admin'
                } else {
                    throw response;
                }
            })
            .catch(error => console.log('error', error));
    }

    render() {
        let state = this.state
        return (
            <div className="mt-4 d-flex justify-content-center align-content-center flex-column flex-wrap">
                <Link className="noUnderline btn btn-secondary text-white fit-content" to={'/admin'}>Zurück</Link>
                <div className="mb-3 w-100">
                    <div className="mb-3 text-center">
                        Kategorie hinzufügen:
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            className="form-control"
                            value={state.value}
                            onChange={this.handleCategoryValueChange}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="btn btn-success" onClick={() => {this.handleSafeBtnPress(state)}}>
                        Speichern
                    </div>
                </div>
            </div>
        );
    }
}