import React, {Component, useState} from 'react'
import Table from 'react-bootstrap/Table'
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";

export default class AdminEditUser extends Component {
    constructor() {
        super()
        this.state = {
            user: null,
            addBalance: 0,
            userid: window.location.href.split('/')[6],
        };

        let myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

        let urlencoded = new URLSearchParams()
        urlencoded.append("user_id", this.state.userid)

        fetch("/api/getAdminSingleUser.php", {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        })
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {

                this.setState({
                    user: data
                })
            })
            .catch(error => console.log('error', error))

    }

    handleUserNameChange = (e) => {
        const updatedName = e.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                name: updatedName
            }
        }));
    }

    handleUserAddBalanceChange = (e) => {
        const updatedBalance = e.target.value;
        this.setState(prevState => ({
            addBalance: updatedBalance
        }));
    }

    handleUserRoleChange = (e) => {
        const updatedRole = e.target.value;
        this.setState(prevState => ({
            user: {
                ...prevState.user,
                role: updatedRole
            }
        }));
    }

    handleUserSafeBtnPress(user, addBalance) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        if (addBalance !== 0)  {
            let urlencodedTrans = new URLSearchParams();
            urlencodedTrans.append("user_id", user.key);
            urlencodedTrans.append("value", addBalance);
            urlencodedTrans.append("fromSystem", "0");

            fetch("/api/addDeposit.php", {
                method: 'POST',
                headers: myHeaders,
                body: urlencodedTrans,
                redirect: 'follow'
            })
                .then(response => {
                    if (response.status === 401) window.location.href = '/#/login';
                    if (response.ok) {
                        console.log("Änderung erfolgreich gespeichert");
                        this.setState(prevState => ({
                            user: {
                                ...prevState.user,
                                balance: newValue
                            }
                        }));
                    } else {
                        throw response;
                    }
                })
                .catch(error => console.log('error', error));
        }

        let newValue = Number(user.balance) + Number(addBalance);

        let urlencoded = new URLSearchParams();
        urlencoded.append("user_id", user.key);
        urlencoded.append("name", user.name);
        urlencoded.append("balance", newValue);
        urlencoded.append("role", user.role);

        fetch("/api/updateUserAdmin.php", {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        })
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login';
                if (response.ok) {
                    console.log("Änderung erfolgreich gespeichert");
                    this.handleBalanceChange()
                    window.location.href = '/#/admin/admin-users'
                } else {
                    throw response;
                }
            })
            .catch(error => console.log('error', error));
    }

    handleBalanceChange = () => {
        fetch("/api/getMyBalance.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                document.getElementById("NavbarBalance").textContent = data.toFixed(2) + "€"
            })
            .catch(error => console.log('error', error))
    }

    render() {
        let {user: user, addBalance} = this.state
        if (user == null) {
            <div>Kein Produkt gefunden</div>
            return;
        }

        return (
            <div className="mt-4 d-flex justify-content-center align-content-center flex-column flex-wrap">
                <Link className="noUnderline btn btn-secondary text-white fit-content mb-3"
                      to={'/admin/admin-users'}>Zurück</Link>
                <div className="mb-3 w-100">
                    <div className="mb-3">
                        <label className="form-label">Name:</label>
                        <input
                            className="form-control"
                            value={user.name}
                            onChange={this.handleUserNameChange}
                        />
                    </div>
                    <div className="mb-3 d-flex flex-row justify-content-evenly">
                        <div>
                            <label className="form-label">Guthaben:</label>
                            <div>
                                {user.balance}
                            </div>
                        </div>
                        <div>
                            <label className="form-label">Guthaben hinzufügen:</label>
                            <input
                                className="form-control"
                                value={addBalance}
                                onChange={this.handleUserAddBalanceChange}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Rolle:</label>
                        <select className="form-select" value={user.role} aria-label="Default select example"
                                onChange={this.handleUserRoleChange}>
                            <option value="admin">Admin</option>
                            <option value="user">User</option>
                            <option value="kiosk">Kiosk</option>
                        </select>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <div className="btn btn-success" onClick={() => {
                        this.handleUserSafeBtnPress(user, addBalance)
                    }}>
                        Speichern
                    </div>
                </div>
            </div>
        );
    }
}