import { HashRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout.js';
import Home from './Home.js';
import Login from './Login.js';
import MyTransactions from './MyTransactions.js';
import BuyProductChooseUser from './BuyProductChooseUser.js';
import BuyProductCheck from './BuyProductCheck.js';
import AdminLayout from './AdminLayout.js';
import AdminDashboardProducts from './AdminDashboardProducts.js';
import AdminDashboardUsers from "./AdminDashboardUsers";
import AdminEditDrink from "./AdminEditDrink";
import './App.css';
import AdminEditUser from "./AdminEditUser";
import AdminFinancialDashboard from "./AdminFinancialDashboard";
import AdminAddDrink from "./AdminAddDrink";
import AdminAddCategory from "./AdminAddCategory";

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="buyproduct/:productid" element={<BuyProductChooseUser />} />
          <Route path="buyproduct/:productid/:userid" element={<BuyProductCheck bla="blah" />} />
          <Route path="productlist" element={<Home />} />
          <Route path="transactions" element={<MyTransactions />} />
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<AdminDashboardProducts />} />
            <Route path="admin-products" element={ <AdminDashboardProducts/>} />
            <Route path="admin-users" element={ <AdminDashboardUsers/>} />
            <Route path="admin-finance" element={ <AdminFinancialDashboard/>} />
            <Route path="adddrink" element={ <AdminAddDrink/>} />
            <Route path="editdrink/:drinkid" element={ <AdminEditDrink/>} />
            <Route path="edituser/:userid" element={ <AdminEditUser/>} />
            <Route path="addcategory" element={<AdminAddCategory/>}/>
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </HashRouter>
  );
}
