import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

export default class BuyProductCheck extends Component {
  constructor() {
    super()
    this.state = {
      user: {},
      product: {},
      productid: window.location.href.split('/')[5],
      userid: window.location.href.split('/')[6],
    }

    this.handleBuy = this.handleBuy.bind(this);

    let myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    let urlencoded = new URLSearchParams()
    urlencoded.append("Drink_ID", this.state.productid)

    fetch("/api/getSingleDrink.php", {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    })
      .then(response => {
        if (response.status === 401) window.location.href = '/#/login'
        if (response.ok) return response.json()
        throw response
      })
      .then(data => this.setState({product: data}))
      .catch(error => console.log('error', error))

      
    urlencoded = new URLSearchParams()
    urlencoded.append("User_ID", this.state.userid)

    fetch("/api/getSingleUser.php", {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    })
      .then(response => {
        if (response.status === 401) window.location.href = '/#/login'
        if (response.ok) return response.json()
        throw response
      })
      .then(data => this.setState({user: data}))
      .catch(error => console.log('error', error))
  }

  handleBuy() {
    let myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    let urlencoded = new URLSearchParams()
    urlencoded.append("drink_id", this.state.productid)
    urlencoded.append("user_id", this.state.userid)

    fetch("/api/buyDrink.php", {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    })
      .then(response => {
        if (response.status === 401) window.location.href = '/#/login'
        if (response.ok) {
          this.handleBalanceChange()
          window.location.href = '/#/productlist';
        }
        throw response
      })
      .catch(error => console.log('error', error))
  }

  handleBalanceChange = () => {
    fetch("/api/getMyBalance.php")
        .then(response => {
          if (response.status === 401) window.location.href = '/#/login'
          if (response.ok) return response.json()
          throw response
        })
        .then(data => {
          document.getElementById("NavbarBalance").textContent = data.toFixed(2) + "€"
        })
        .catch(error => console.log('error', error))
  }

  render() {
    let {user, product} = this.state

    return (
      <div className="container mt-4">
        <Button href='/#/productlist' className="mb-3 btn btn-success">Abbrechen</Button>
        <div className="row justify-content-around align-items-center mt-3">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="d-flex">
              <img className="img-fluid mx-auto w-25" alt="Produktbild" src={product.image} />
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <p className="h3 text-center">1x <b>{product.name}</b> kaufen für <b>{user.name}</b>. Gesamtpreis: {product.price}€</p>
            <Button className="mb-2 mt-4 w-100 btn btn-success" onClick={this.handleBuy} >Kaufen</Button>
          </div>
        </div>
      </div>
    );
  }
}