import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import { Outlet, Link } from "react-router-dom";

export default class MyTransactions extends Component {
    constructor() {
        super()

    }

    render() {
        return (
            <div className="container mt-4">
                <Outlet></Outlet>
            </div>
        );
    }
}