import React, {Component} from 'react'
import Table from 'react-bootstrap/Table'

export default class MyTransactions extends Component {
    constructor() {
        super()
        this.state = {
            transactions: [],
            transactionSum: 0,
            deposits: [],
            depositSum: 0,
            balance: 0,
            me: {},
        }

        fetch("/api/getMyTransactions.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                if (data.length > 0) {
                    this.setState({transactions: data[0], transactionSum: data[1]})
                }
            })
            .catch(error => console.log('error', error))

        fetch("/api/getMyDeposit.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                if (data.length > 0) {
                    this.setState({deposits: data[0], depositSum: data[1]})
                }
            })
            .catch(error => console.log('error', error))

        fetch("/api/getMyBalance.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => this.setState({balance: data.toFixed(2)}))
            .catch(error => console.log('error', error))

        fetch("/api/getMyUser.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => this.setState({me: data}))
            .catch(error => console.log('error', error))
    }

    render() {
        let {transactions, balance, transactionSum, deposits, depositSum} = this.state
        const transactionlist = transactions.map((transaction) => (
            <tr>
                <td>{transaction.time}</td>
                <td>{transaction.name}</td>
                <td>{transaction.value}€</td>
            </tr>
        ))
        const depositlist = deposits.map((deposit) => (
            <tr>
                <td>{deposit.time}</td>
                <td>{deposit.value}€</td>
            </tr>
        ))

        return (
            <div className="container mt-4">
                <p className="h3 mb-3">Mein Kontostand: {balance} €</p>

                <div className="fw-bold ms-1 mb-2 fs-5">Käufe:</div>
                <Table bordered>
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Produkt</th>
                        <th>Preis</th>
                    </tr>
                    </thead>
                    <tbody>
                    {transactionlist}
                    </tbody>
                </Table>
                <div className="d-flex justify-content-end me-2">
                    <div className="fw-bold">Meine Ausgaben: {transactionSum}</div>
                </div>

                <div className="fw-bold ms-1 mb-2 fs-5">Ein- / Aus-zahlungen:</div>
                <Table bordered>
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Betrag</th>
                    </tr>
                    </thead>
                    <tbody>
                    {depositlist}
                    </tbody>
                </Table>
                <div className="d-flex justify-content-end me-2 mb-4">
                    <div className="fw-bold">Meine Einzahlungen: {depositSum}</div>
                </div>

            </div>
        );
    }
}

