import React, { Component, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Card from "react-bootstrap/Card";
import {Link} from "react-router-dom";

export default class AdminFinancialDashboard extends Component {
    constructor() {
        super()
        this.state = {
            me: {},
            deposits: [],
            depositSum: 0,
            depositInput: 0,
            drinkSum: 0
        };

        fetch("/api/getMyUser.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => this.setState({ me: data }))
            .catch(error => console.log('error', error))

        fetch("/api/getAdminDeposits.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                if (data.message) {
                    this.setState({deposits: []})
                }
                else {
                    this.setState({
                        deposits: data[0],
                        depositSum: data[1]
                    });
                }
            })
            .catch(error => console.log('error', error))

        fetch("/api/getAdminDrinksValue.php")
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login'
                if (response.ok) return response.json()
                throw response
            })
            .then(data => {
                if (data.message) {
                    this.setState({drinkSum: 0})
                }
                else {
                    this.setState({
                        drinkSum: data
                    });
                }
            })
            .catch(error => console.log('error', error))
    }

    handleDepositValueChange = (e) => {
        const updatedValue = e.target.value;
        this.setState({
            depositInput: updatedValue
        });
    }

    handleAddDepositBtn(depositInput, me) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("user_id", me);
        urlencoded.append("value", depositInput);
        urlencoded.append("fromSystem", "1");


        fetch("/api/AddDeposit.php", {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        })
            .then(response => {
                if (response.status === 401) window.location.href = '/#/login';
                if (response.ok) {
                    console.log("Änderung erfolgreich gespeichert");

                    fetch("/api/getAdminDeposits.php")
                        .then(response => {
                            if (response.status === 401) window.location.href = '/#/login'
                            if (response.ok) return response.json()
                            throw response
                        })
                        .then(data => {
                            if (data.message) {
                                this.setState({deposits: []})
                            }
                            else {
                                this.setState({
                                    deposits: data[0],
                                    depositSum: data[1]
                                });
                            }
                        })
                        .catch(error => console.log('error', error))




                } else {
                    throw response;
                }
            })
            .catch(error => console.log('error', error));



    }

    render() {
        const {depositInput, me, depositSum, drinkSum} = this.state
        const depositslist = this.state.deposits.map((deposits) => {

            return (
                <tr key={deposits.key}>
                    <td className="text-center">{deposits.value}</td>
                    <td className="text-center">
                        <div>{deposits.time}</div>
                    </td>
                </tr>
            );
        });

        return (
            <div className="container mt-4">
                <div className="fw-bold mb-2">Allgemein:</div>
                <div className="d-flex align-content-center justify-content-evenly flex-wrap text-center mb-3">
                    <div>
                        <div>Summe Kasse</div>
                        <div>{depositSum}</div>
                    </div>
                    <div>
                        <div>Summe Getränke-Wert</div>
                        <div>{drinkSum}</div>
                    </div>
                </div>
                <div className="fw-bold mb-2">
                    Ein/Auszahlungen:
                </div>
                <Table bordered>
                    <thead>
                    <tr>
                        <th>Wert</th>
                        <th>Zeit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {depositslist}
                    </tbody>
                </Table>
                <div className="d-flex align-content-center justify-content-center flex-wrap text-center ">
                    <input className="form-control fit-content me-3" value={this.state.depositInput} placeholder="Wert" onChange={this.handleDepositValueChange}/>
                    <button className="btn btn-success fit-content" onClick={ () => { this.handleAddDepositBtn(depositInput, me) }}>Hinzufügen</button>
                </div>
            </div>
        );
    }
}